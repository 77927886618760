// default config for all the environments
const defaultConfig = {
  language: "en",
};
 
// write here every environmet configuration
const modes = {
  development: {
    ...defaultConfig,
    // API_URL: "http://localhost:3000/api/v1/admin" //local-server
    API_URL: "https://kangi2-server-stg.kangiclub.com/api/v1/admin" //stg-server
  },
  // staging: {
  //   ...defaultConfig,
  //   API_URL: "TODO: config",
  // },
  // production: {
  //   ...defaultConfig,
  //   API_URL: "TODO: config",
  // },
};

const appMode = modes[process.env.REACT_APP_ENV] || modes.development;

export default appMode;

export const defaultTimezone = "Etc/UTC";